
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Recovery",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const login = async () => {
      await store.dispatch("auth/login", { token: route.query.key });
      await router.push({ name: "Profile" });
    };

    onMounted(login);
  },
});
